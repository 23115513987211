import React from 'react'
import Router from 'Routes/Routes'
import { useNavigate } from 'react-router'
import { addResponseInterceptor } from 'Services/config'
import 'Styles/Common.css'
import 'Styles/Table.css'
import 'Styles/Modal.css'

function App() {
  const navigate = useNavigate();
  // Set up response interceptor with navigation function
  addResponseInterceptor(navigate);
  return (
    <div><Router /></div>
  )
}

export default App