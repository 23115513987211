import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Styles from "./Filter.module.css";
import { IoSearchSharp } from "react-icons/io5";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { filter } from "lodash";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";

Modal.setAppElement("#root");
function Filter({
    isOpen,
    setIsOpen,
    data,
    setFilters,
    filters,
    setEquipmentFilters,
    internalFilter,
    setInteralfilter,
    searchString,
}) {
    const [Active, setActive] = useState(1);
    const [options, setOptions] = useState([]);
    // console.log(onchange);
    const [value, onChange] = useState([null, null]);
    const [value1, onChange1] = useState([null, null]);
    const [selected, setSelected] = useState([]);
    const FilterButtons = [
        {
            name: "Place ID",
            id: 1,
        },
        {
            name: "Status",
            id: 2,
        },
    ];
    useEffect(() => {
        if (Active === 1) {
            if (filters.length > 0) {
                setInteralfilter(filters);
                let selectedFilter = filters.filter(
                    (item) => item.FilterName == "Place ID"
                );
                if (selectedFilter?.length > 0) {
                    setSelected(
                        selectedFilter[0]?.keys?.map((item) => ({
                            label: item,
                            value: item,
                        }))
                    );
                }
            }
            setOptions(
                [...new Set(data?.map((item) => item?.placeId))]?.map(
                    (item) => ({
                        label: item,
                        value: item,
                    })
                )
            );
        }
        if (Active === 2) {
            setOptions([
                {
                    label: "Active",
                    value: 1,
                },
                {
                    label: "Locked",
                    value: 2,
                },
            ]);
        }
    }, [Active]);
    const ActiveHandler = (e) => {
        const id = Number(e.currentTarget.getAttribute("id"));
        if (id === 1) {
            let selectedFilter = internalFilter.filter(
                (item) => item.FilterName == "Place ID"
            );
            if (selectedFilter?.length > 0) {
                setSelected(
                    selectedFilter[0]?.keys?.map((item) => ({
                        label: item,
                        value: item,
                    }))
                );
            } else {
                setSelected([]);
            }
        }
        if (id === 2) {
            let selectedFilter = internalFilter.filter(
                (item) => item.FilterName == "Status"
            );
            if (selectedFilter?.length > 0) {
                setSelected(
                    selectedFilter[0]?.keys?.map((item) => ({
                        label: item,
                        value: item,
                    }))
                );
            } else {
                setSelected([]);
            }
        }

        setActive(Number(id));
    };
    const SelectHandler = (keys) => {
        setSelected(keys);
        if (Active == 1) {
            let allFilters = [...internalFilter];
            let filteredData = allFilters.filter(
                (item) => item.FilterName != "Place ID"
            );
            if (keys.length > 0) {
                setInteralfilter((prev) => [
                    ...filteredData,
                    {
                        FilterName: "Place ID",
                        Bkey: "placeId",
                        keys: keys?.map((item) => item.value),
                        label: keys?.map((item) => item.label),
                    },
                ]);
            } else {
                setInteralfilter(filteredData);
            }
        }
        if (Active == 2) {
            let allFilters = [...internalFilter];
            let filteredData = allFilters.filter(
                (item) => item.FilterName != "Status"
            );
            if (keys.length > 0) {
                setInteralfilter((prev) => [
                    ...filteredData,
                    {
                        FilterName: "Status",
                        Bkey: "status",
                        keys: keys?.map((item) => item.value),
                        label: keys?.map((item) => item.label),
                    },
                ]);
            } else {
                setInteralfilter(filteredData);
            }
        }
    };
    const clearhandler = () => {
        setFilters([]);
        setInteralfilter([]);
        localStorage.removeItem("UserFilter");
        setEquipmentFilters(data);
        setSelected([]);
        setIsOpen(false);
    };
    // Define a function to filter objects based on the specified Bkey
    function filterObjectsByBkey(objects, filter) {
        const { Bkey, keys } = filter;
        return objects.filter((obj) => keys.includes(obj[Bkey]));
    }
    console.log(options, data);
    const ApplyHandler = () => {
        let filteredResults = data;

        let Internal = internalFilter;
        for (const filter of internalFilter) {
            filteredResults = filterObjectsByBkey(filteredResults, filter);
            console.log(filteredResults);
        }
        if (searchString.length > 0) {
            filteredResults = filteredResults.filter((user) =>
                user.emailId
                    .toLowerCase()
                    .includes(String(searchString).toLowerCase())
            );
        }
        // console.log(filteredResults);
        setEquipmentFilters([...filteredResults]);
        console.log([...filteredResults]);
        setIsOpen(false);
        setFilters([...Internal]);
        localStorage.setItem("UserFilter", JSON.stringify([...Internal]));
    };
    return (
        <Modal
            isOpen={isOpen}
            contentLabel="Modal"
            onRequestClose={() => setIsOpen(false)}
            className={Styles["modal"]}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["Container"]}>
                <div className={Styles["filter"]}>
                    <div className={Styles["left_filter"]}>
                        <h3>Filter</h3>
                        {FilterButtons.map((item) => (
                            <button
                                id={item.id}
                                onClick={ActiveHandler}
                                className={
                                    Active == item.id ? Styles["button"] : ""
                                }>
                                {item.name}
                            </button>
                        ))}
                    </div>
                    <div className={Styles["filter_input"]}>
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={SelectHandler}
                            labelledBy={"Select"}
                            className={Styles["react-mulit-select"]}
                        />

                        <IoSearchSharp className={Styles["icon"]} />

                        <div className={"Filters_wrapper"}>
                            {filters.map((item) => (
                                <div className={"Filters"}>
                                    <h3>{item?.FilterName}</h3>

                                    <p>{item?.keys?.join(",")}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={Styles["clear"]}>
                    <button onClick={clearhandler}>Clear</button>
                    <button onClick={ApplyHandler}>Apply</button>
                </div>
            </div>
        </Modal>
    );
}

export default Filter;
