import React, { useState, useEffect } from "react";
import Layout from "Components/Layout";
import Moment from "react-moment";
import Loader from "Components/Loader/Loader";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";

import Swal from "sweetalert2";
import {
    GetLogs,
    GetPermissions,
    GetLogsSearch,
} from "Services/ServiceRequest";
import Subloader from "Components/Loader/subload/loader";
import { useForm } from "react-hook-form";
function User() {
    const [loading, setLoading] = useState(true);
    const [Permissions, setPermissions] = useState({ RolePermissions: [] });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [offset, setOffset] = useState(0);
    const [Data, setData] = useState({});
    const [filter, setFilter] = useState(false);
    const [Logdesc, setLogdesc] = useState([]);
    const [TotalRecords, setTotalRecords] = useState(0);
    const [offsetCount, setOffsetCount] = useState(30);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const [requests, setRequests] = useState([]);
    const [PermissionNames, setPermissionNames] = useState([]);

    const [subLoad, setSubLoad] = useState(false);
    // const [Modal, setModal] = useState(false);
    const [Logs, setLogs] = useState([]);

    const GetRequests = async (offset) => {
        // setLoading(true);

        try {
            let response;
            if (filter) {
                response = await GetLogsSearch(offset, Data);
            } else {
                response = await GetLogs(offset);
                // response =
            }
            setLoading(false);
            setSubLoad(false);
            if (response?.data?.Status === 1) {
                setLogs(response?.data?.Logs);
                setTotalRecords(response?.data?.TotalLogs / offsetCount);
            }
        } catch (err) {
            setLoading(false);
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const getPermissions = async () => {
        // setLoading(true);
        try {
            const response = await GetPermissions(1);

            if (response?.data?.Status === 1) {
                setPermissions(response?.data);
            } else {
            }
            setLoading(false);
            setSubLoad(false);
        } catch (err) {
            setSubLoad(false);
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    useEffect(() => {
        GetRequests(offset);
        console.log("2");
    }, [offset, Data]);
    console.log(Data);
    useEffect(() => {
        getPermissions();
    }, []);

    const handlePageClick = (event) => {
        setLoading(true);
        setOffset(event.selected * offsetCount);
    };
    const onSubmit = (data) => {
        // console.log(data);
        if (startDate > endDate) {
            Swal.fire({
                title: "warning",
                text: "Invalid Date range",
                icon: "warning",
            });
            return;
        }
        setSubLoad(true);
        setFilter(true);
        let FormData;

        if (endDate && startDate) {
            FormData = {
                fromdate: startDate,
                endDate: endDate,
            };
        }
        if (data?.userName.length > 0) {
            FormData = { ...FormData, userName: data?.userName };
        }
        if (data?.Page.length > 0) {
            FormData = { ...FormData, pageName: data?.Page };
        }
        if (data?.PermissionName.length > 0) {
            FormData = { ...FormData, section: data?.PermissionName };
        }
        if (data?.logDescription.length > 0) {
            FormData = { ...FormData, logDescription: data?.logDescription };
        }
        setTimeout(() => {
            setData(FormData);
            setOffset(0);
            console.log("333");
        }, 500);
    };

    const changeHandler = (e) => {
        let filteredData = Permissions.RolePermissions.filter(
            (item) => item.PageName == e.target.value
        );
        console.log(filteredData);
        setLogdesc(
            filteredData.flatMap((page) =>
                page.Sections.flatMap((section) => section.SectionPermissions)
            )
        );
        setPermissionNames(
            filteredData[0].Sections.map((item) => item.SectionName)
        );
    };
    return (
        <Layout Active={"logs"} showBackButton={false} title={"Admin Users"}>
            {subLoad && <Subloader />}

            <div className="Main_container">
                <h3>Logs</h3>
                <Loader loading={loading}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="filter-form">
                        <div className="filter-form-control">
                            <label htmlFor="">Name</label>
                            <input
                                type="text"
                                {...register("userName")}
                                placeholder="Search by Name"
                            />
                        </div>

                        <div
                            className="filter-form-control"
                            style={{ zIndex: 5 }}>
                            <label htmlFor="">Request Date</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDate(start);
                                    setEndDate(end);
                                }}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                            />
                        </div>
                        <div className="filter-form-control">
                            <label htmlFor="">Page Name</label>
                            <select
                                name=""
                                id=""
                                {...register("Page")}
                                onChange={changeHandler}>
                                <option value="">Select Page</option>
                                {Permissions.RolePermissions.map((item) => (
                                    <option value={item.PageName}>
                                        {item.PageName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="filter-form-control">
                            <label htmlFor="">Section</label>
                            <select {...register("PermissionName")}>
                                <option value="">Select Permission</option>
                                {PermissionNames.map((item) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <div className="filter-form-control">
                            <label htmlFor="">Log</label>
                            <select {...register("logDescription")}>
                                <option value="">Select Log</option>
                                <option value="updated password">
                                    Updated Password
                                </option>
                                <option value="reset password">
                                    Reset Password
                                </option>
                                <option value="logged in successfully">
                                    Logged in Successfully
                                </option>
                                {Logdesc.map((item) => (
                                    <option value={item.permissionName}>
                                        {item.permissionName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            className="form_control_filter_button"
                            type="submit">
                            Submit
                        </button>
                        <button
                            className="form_control_filter_button"
                            type="button"
                            style={{ background: "red", color: "white" }}
                            onClick={() => {
                                reset();
                                setFilter(false);
                                setSubLoad(true);
                                setTimeout(() => {
                                    setData();
                                    setOffset(0);
                                    setFilter(false);
                                }, 500);
                            }}>
                            Clear
                        </button>
                    </form>
                    <div
                        className="Table_layout"
                        style={{
                            height: "100%",
                            minHeight: "10rem",
                            maxHeight: "60vh",
                        }}>
                        <table>
                            <thead className="sticky-header">
                                <tr>
                                    <th>User ID</th>
                                    <th>Full Name</th>
                                    <th>Page Name</th>
                                    <th>Section</th>
                                    <th>Log Description</th>
                                    <th>IP Address</th>
                                    <th>Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Logs.length > 0 ? (
                                    Logs.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.userId}</td>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.pageName}</td>
                                            <td>{item?.section}</td>
                                            <td>{item?.logDescription}</td>
                                            <td>{item?.ipAddress}</td>
                                            <td>
                                                <Moment
                                                    format={"YYYY-MM-DD HH:MM"}
                                                    utc>
                                                    {item?.createdAt}
                                                </Moment>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {" "}
                                        <td colSpan={7}>No Data Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Loader>
                <div className="paginate-wrapper">
                    {Logs.length > 0 && (
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={TotalRecords}
                            previousLabel="previous"
                            renderOnZeroPageCount={null}
                            breakClassName={"break-me"}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"selected"}
                            disabledClassName={"disabled"}
                        />
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default User;
