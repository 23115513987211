import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, Link, NavLink } from "react-router-dom";
import ReactLoading from "react-loading";
import OtpInput from "react-otp-input";

import { Tooltip } from "react-tooltip";
//Assets

import LoginClasses from "./Login.module.css";
import logo from "assets/login/login.png";
import Instagram from "assets/login/instagram.png";
import Linkedin from "assets/login/linkedin.png";
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { LoginService, verifyOtp } from "Services/ServiceRequest";
import ApiService from "Services/config";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [success, setSuccess] = useState("");
    const [errorMsg, setErrormsg] = useState("");
    const [otp, setOtp] = useState("");

    const Navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();
    console.log(loading);
    const onSubmit = async (data) => {
        setLoading(true);
        setErrormsg("");
        setSuccess("");
        if (showOtp) {
            try {
                const response = await verifyOtp(data.emailId, otp);
                setLoading(false);
                if (response?.data?.Status === 1) {
                    // setError("");
                    ApiService.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${response?.data?.Token}`;
                    localStorage.setItem("Token", response?.data?.Token);
                    Navigate("/AdminUsers");
                    localStorage.setItem(
                        "fullName",
                        response?.data?.AdminDetails?.fullName
                    );
                    localStorage.setItem(
                        "AdminData",
                        JSON.stringify(response?.data?.AdminDetails)
                    );
                }
            } catch (err) {
                setLoading(false);

                setErrormsg(
                    err?.response?.data?.error?.name ||
                        err?.response?.data?.Message ||
                        "Something went wrong try again later"
                );
            }
        } else {
            try {
                const response = await LoginService(data);
                setLoading(false);
                if (response?.data?.Status === 1) {
                    setShowOtp(true);
                    setSuccess(response?.data?.Message);
                    // setError("");
                    setErrormsg("");
                    /*   ApiService.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${response?.data?.Token}`;
                    localStorage.setItem("token", response?.data?.Token);
                    Navigate("/profile");
                    localStorage.setItem("fullName", response?.data?.fullName); */
                }
            } catch (err) {
                setLoading(false);

                setErrormsg(
                    err?.response?.data?.error?.name ||
                        err?.response?.data?.Message ||
                        "Something went wrong try again later"
                );
            }
        }
    };

    return (
        <div className={LoginClasses["login-container"]}>
            <div className={LoginClasses["logo"]}>
                <div className={LoginClasses["login_logo"]}>
                    <img
                        src={logo}
                        alt="logo"
                        className={LoginClasses["login-img"]}
                    />
                    <p className={LoginClasses["logo_text"]}>
                        The way LCMS service should always be
                    </p>
                </div>
            </div>
            <div className={LoginClasses["login_container2"]}>
                <form
                    className={LoginClasses["login-form"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <h1 className={LoginClasses["login_header"]}>
                        Admin Login
                    </h1>

                    <div className={LoginClasses["login-input-container"]}>
                        <div className={LoginClasses["Input_wrapper"]}>
                            <FaEnvelope
                                className={LoginClasses["login-input-icon"]}
                            />
                            <input
                                className={`${
                                    errors?.emailId
                                        ? LoginClasses["input-error"]
                                        : ""
                                } ${LoginClasses["login-email"]}`}
                                type="email"
                                {...register("emailId", {
                                    required: "Email is required",
                                })}
                                placeholder="Email Address"
                            />
                        </div>
                        {errors?.emailId && (
                            <span>{errors?.emailId?.message}</span>
                        )}
                    </div>
                    <div className={LoginClasses["login-input-container"]}>
                        <div
                            className={LoginClasses["Input_wrapper"]}
                            data-tooltip-id="my-tooltip">
                            <FaLock
                                className={LoginClasses["login-input-icon"]}
                            />
                            <input
                                className={`${
                                    errors?.password
                                        ? LoginClasses["input-error"]
                                        : ""
                                } ${LoginClasses["login-password"]}`}
                                type={showPassword ? "text" : "password"}
                                {...register("password", { required: true })}
                                placeholder="Password"
                            />

                            {showPassword ? (
                                <FaEyeSlash
                                    className={
                                        LoginClasses["password-toggle-icon"]
                                    }
                                    onClick={togglePasswordVisibility}
                                />
                            ) : (
                                <FaEye
                                    className={
                                        LoginClasses["password-toggle-icon"]
                                    }
                                    onClick={togglePasswordVisibility}
                                />
                            )}
                        </div>
                        <Tooltip
                            id="my-tooltip"
                            place="bottom-end"
                            variant="dark"
                            style={{ zIndex: "1" }}>
                            <p style={{ color: "white" }}>
                                Password must be atleast{"  "}
                                <span
                                    style={{
                                        color: "red",
                                        marginLeft: "0.2rem",
                                    }}>
                                    8 Character
                                </span>{" "}
                                and contain
                                <span
                                    style={{
                                        color: "green",
                                        marginLeft: "0.2rem",
                                    }}>
                                    1 number,1 uppercase <br />
                                    letter ,1 lowercase letter{" "}
                                </span>{" "}
                                and{" "}
                                <span
                                    style={{
                                        color: "red",
                                        marginLeft: "0.2rem",
                                    }}>
                                    1 symbol
                                </span>
                            </p>
                        </Tooltip>
                        {errors?.password && (
                            <span>{errors?.password?.message}</span>
                        )}
                    </div>
                    {showOtp && (
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            containerStyle={LoginClasses["otpContainer"]}
                            inputStyle={LoginClasses["otpinput"]}
                            inputType="number"
                            shouldAutoFocus
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    )}
                    {success.length > 0 && (
                        <p className={LoginClasses["success-msg"]}>{success}</p>
                    )}
                    {errorMsg.length > 0 && (
                        <p className={LoginClasses["error-msg"]}> {errorMsg}</p>
                    )}
                    <button
                        className={LoginClasses["login-submit"]}
                        type="submit"
                        disabled={loading}>
                        {loading ? (
                            <ReactLoading
                                color="white"
                                type="spokes"
                                height={20}
                                width={20}
                            />
                        ) : (
                            "Login"
                        )}
                    </button>

                    <div className={LoginClasses["login-social-media"]}>
                        <h5
                            className={
                                LoginClasses["login-social-media-heading"]
                            }>
                            FIND US ON
                        </h5>
                        <Link
                            to="https://www.instagram.com/zefscientificinc/"
                            target="_blank">
                            <span
                                className={
                                    LoginClasses["login-social-media-img"]
                                }>
                                <img src={Instagram} alt="instagram" />
                            </span>
                        </Link>
                        <Link
                            to="https://www.linkedin.com/company/zefsci-zef-scientific-inc./"
                            target="_blank">
                            <span
                                className={
                                    LoginClasses["login-social-media-img"]
                                }>
                                <img src={Linkedin} alt="LinkedIn" />
                            </span>
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
