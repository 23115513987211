import AdimUsers from "Pages/AdminUser/User"
import Customers from 'Pages/Customers/User'
import Permissions from 'Pages/Permissions/Permissions'
import ApproveRequest from 'Pages/ApproveRequest/request'
import Logs from 'Pages/Logs/Logs'
import Notifications from "Pages/Notifications/Notifications"
export const AccessKeys = {
 adminUsers: 'AdminUsers',
 Customers: 'Customers',
 Permissions: 'Permissions',
 ApproveRequest: 'ApproveRequest',
 Logs: 'Logs',
 Notifications: "Notifications"
}

export const PageRoutes = [
 { path: '/AdminUsers', element: <AdimUsers />, AccessKey: AccessKeys.adminUsers, Type: 'read' },
 { path: '/customers', element: <Customers />, AccessKey: AccessKeys.Customers, Type: 'read' },
 { path: '/permissions', element: <Permissions />, AccessKey: AccessKeys.Permissions, Type: 'read' },
 { path: '/approve-request', element: <ApproveRequest />, AccessKey: AccessKeys.ApproveRequest, Type: 'read' },
 { path: '/logs', element: <Logs />, AccessKey: AccessKeys.Logs, Type: 'read' },
 { path: '/notifications', element: <Notifications />, AccessKey: AccessKeys.Notifications, Type: 'read' },

]