import React, { useState, useEffect } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { addAdminUser, UpdateAdminUsers } from "Services/ServiceRequest";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import ReactLoading from "react-loading";
function AddUser({ setModal, showModal, GetRequests, edit, setEdit }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const closeModal = () => {
        if (!loading) {
            setModal(false);
            reset({ role: 2, emailId: "", password: "", fullName: "" });
            setError("");
            setEdit(false);
            localStorage.removeItem("prefillData");
        }
    };
    useEffect(() => {
        if (localStorage.getItem("prefillData")) {
            reset(JSON.parse(localStorage.getItem("prefillData")));
        }
    }, [edit]);
    const onSubmit = async (data) => {
        setLoading(true);
        const adminData = JSON.parse(localStorage.getItem("prefillData"));
        if (edit) {
            let body = { emailId: data.emailId, fullName: data?.fullName };
            try {
                const response = await UpdateAdminUsers(
                    adminData?.adminUserId,
                    {
                        ...body,
                    }
                );
                setLoading(false);
                if (response?.data?.Status === 1) {
                    localStorage.removeItem("prefillData");
                    GetRequests();
                    setEdit(false);
                    Swal.fire({
                        title: "Success",
                        text:
                            response?.data?.Message ||
                            "Admin Update Successfull",
                        icon: "success",
                    });
                    closeModal();
                } else {
                    setError(
                        error?.response?.data?.error?.name ||
                            response?.data?.Message ||
                            "Something went wrong please try again"
                    );
                }
            } catch (err) {
                setLoading(false);
                setError(
                    err?.response?.data?.Message ||
                        "Something went wrong please try again"
                );
            }
        } else {
            try {
                const response = await addAdminUser({
                    ...data,
                    role: Number(data.role),
                });
                setLoading(false);
                if (response?.data?.Status === 1) {
                    GetRequests();
                    setEdit(false);
                    Swal.fire({
                        title: "Success",
                        text:
                            response?.data?.Message ||
                            "Admin Added Successfull",
                        icon: "success",
                    });
                    closeModal();
                } else {
                    setError(
                        error?.response?.data?.error?.name ||
                            response?.data?.Message ||
                            "Something went wrong please try again"
                    );
                }
            } catch (err) {
                setLoading(false);
                setError(
                    err?.response?.data?.Message ||
                        "Something went wrong please try again"
                );
            }
        }
    };

    return (
        <Modal
            Modal
            isOpen={showModal}
            contentLabel="Modal"
            className={"modal"}
            onRequestClose={closeModal}
            overlayClassName={"overlay"}>
            <div className="form_wrapper_Modal">
                <header>
                    <h3>{edit ? "Update" : "Add"} Admin User</h3>
                    <button onClick={closeModal} disabled={loading}>
                        <IoCloseCircleOutline />
                    </button>
                </header>
                <form
                    form
                    onSubmit={handleSubmit(onSubmit)}
                    className="Add_form">
                    <div
                        className="Form_Control"
                        style={{ gridColumn: edit ? "1/-1" : "initial" }}>
                        <label htmlFor="">Name</label>
                        <input
                            className={errors.fullName ? "error" : ""}
                            {...register("fullName", { required: true })}
                        />
                        {errors.fullName && <span>This field is required</span>}
                    </div>
                    {!edit && (
                        <div className="Form_Control">
                            <label htmlFor="">password</label>
                            <input
                                type="password"
                                autoComplete="new-password"
                                className={errors.password ? "error" : ""}
                                {...register("password", { required: true })}
                            />
                            {errors.password && (
                                <span>This field is required</span>
                            )}
                        </div>
                    )}
                    <div className="Form_Control full-width">
                        <label htmlFor="">Email ID</label>
                        <input
                            className={errors.emailId ? "error" : ""}
                            {...register("emailId", { required: true })}
                        />
                        {errors.emailId && <span>This field is required</span>}
                    </div>

                    {!edit && (
                        <div className="Form_Control full-width">
                            <label htmlFor="">Role</label>
                            <select
                                className={errors.role ? "error" : ""}
                                {...register("role", { required: true })}>
                                <option value={2}>Account Manager</option>
                            </select>
                            {errors.role && <span>This field is required</span>}
                        </div>
                    )}
                    {error.length > 0 && (
                        <p
                            className="full-width"
                            style={{ color: "red", fontSize: "0.9rem" }}>
                            {error}
                        </p>
                    )}
                    <div className="add_form_btns full-width">
                        <button type="submit" disabled={loading}>
                            {loading ? (
                                <ReactLoading
                                    color="blue"
                                    type="spokes"
                                    height={20}
                                    width={20}
                                />
                            ) : edit ? (
                                "Update User"
                            ) : (
                                "Add User"
                            )}
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            disabled={loading}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
}

export default AddUser;
