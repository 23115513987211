import React, { useState, useEffect, useLayoutEffect } from "react";
import navbarClasses from "./navbar.module.css";
import { useNavigate, NavLink } from "react-router-dom";
import { menuItems } from "./Config";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaBell, FaRegBell } from "react-icons/fa";

import Logo from "assets/logo.png";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import { motion, AnimatePresence } from "framer-motion";
import useStore from "Store";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

function Sidenav({ Active, show, setShow }) {
    const Navigate = useNavigate();
    const dropDown = useStore((item) => item.dropdown);
    const setDropdown = useStore((item) => item.setDropdown);
    const shouldAnimate = useStore((item) => item.shouldAnimate);
    const setShouldAnimate = useStore((item) => item.setShouldAnimate);

    const hasPermission = (permissions) => {
        let storageData = localStorage.getItem("Token");
        if (storageData) {
            return true;
        } else return false;
    };

    const clickhandler = () => {
        setDropdown("");

        setShow((prev) => !prev);
    };
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });

    const closeHnadler = () => {
        if (window.innerWidth <= 900 && show) {
            setShow(false);
            setDropdown("");
        }
    };
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        closeHnadler();
    };

    useEffect(() => {
        window.addEventListener("resize", setDimension);

        return () => {
            window.removeEventListener("resize", setDimension);
        };
        // eslint-disable-next-line
    }, [screenSize]);

    return (
        <div>
            <div
                className={`${show ? navbarClasses["backdrop"] : ""}`}
                onClick={() => {
                    setShow(false);
                    setDropdown("");
                }}></div>
            <div
                className={`${navbarClasses["Container"]} ${
                    !show ? navbarClasses["hide"] : ""
                }`}>
                <div>
                    <div className={navbarClasses["hamburger"]}>
                        <img
                            className={!show && navbarClasses["img-size"]}
                            src={Logo}
                            alt="logo"
                            onClick={() => {
                                window.open(
                                    "/",
                                    "_blank",
                                    "noopener,noreferrer"
                                );
                            }}
                        />
                        <button
                            className={navbarClasses["hamburger-icon"]}
                            onClick={clickhandler}>
                            <GiHamburgerMenu size={22} color="white" />
                        </button>
                    </div>

                    <div className={navbarClasses["navlinks"]}>
                        {menuItems.map((menuData) => {
                            if (!menuData.multiple) {
                                if (hasPermission(menuData.name)) {
                                    return (
                                        <>
                                            <NavLink
                                                key={menuData.id}
                                                id={menuData.id}
                                                to={menuData.menuLink}
                                                data-tooltip-id={menuData.id}
                                                data-tooltip-content={
                                                    menuData.name
                                                }
                                                data-tooltip-position-strategy={
                                                    "fixed"
                                                }
                                                className={
                                                    Active === menuData.active
                                                        ? navbarClasses[
                                                              "navActive"
                                                          ]
                                                        : navbarClasses[
                                                              "navInactive"
                                                          ]
                                                }>
                                                <menuData.icon
                                                    className={
                                                        navbarClasses[
                                                            "nav-icon"
                                                        ]
                                                    }
                                                />
                                                {show && (
                                                    <span>{menuData.name}</span>
                                                )}
                                            </NavLink>{" "}
                                            <ReactTooltip
                                                id={menuData.id}
                                                place="left"
                                                variant="info"
                                                hidden={show}
                                            />
                                        </>
                                    );
                                }
                                return null;
                            } else {
                                let allData = [];
                                menuData.items.forEach((item) => {
                                    if (hasPermission(item.name)) {
                                        allData.push(item);
                                    }
                                });
                                if (allData.length > 0) {
                                    return (
                                        <div className={navbarClasses["seo"]}>
                                            <div
                                                data-tooltip-id={menuData.id}
                                                data-tooltip-content={
                                                    menuData.name
                                                }
                                                data-tooltip-position-strategy={
                                                    "fixed"
                                                }
                                                className={
                                                    navbarClasses["seo-item"]
                                                }
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                        dropDown ==
                                                        menuData.name
                                                    ) {
                                                        setDropdown("");
                                                    } else {
                                                        if (!show) {
                                                            setShow(true);
                                                        }
                                                        setDropdown(
                                                            menuData.name
                                                        );
                                                    }
                                                }}>
                                                <menuData.icon
                                                    data-tooltip-id={
                                                        menuData.id
                                                    }
                                                    data-tooltip-content={
                                                        menuData.name
                                                    }
                                                    data-tooltip-position-strategy={
                                                        "fixed"
                                                    }
                                                    className={
                                                        navbarClasses[
                                                            "nav-icon"
                                                        ]
                                                    }
                                                />
                                                <ReactTooltip
                                                    id={menuData.id}
                                                    place="left"
                                                    variant="info"
                                                    hidden={show}
                                                />
                                                {show && (
                                                    <p>
                                                        <span>
                                                            {/*   {menuData.name ==
                                                            "Support" && (
                                                            <div
                                                                class={
                                                                    navbarClasses[
                                                                        "red-dot"
                                                                    ]
                                                                }></div>
                                                        )} */}
                                                            {menuData.name}{" "}
                                                        </span>
                                                        {dropDown ==
                                                        menuData.name ? (
                                                            <RiArrowDropUpFill
                                                                size={30}
                                                            />
                                                        ) : (
                                                            <RiArrowDropDownFill
                                                                size={30}
                                                            />
                                                        )}
                                                    </p>
                                                )}
                                            </div>
                                            <AnimatePresence
                                                initial={
                                                    !(dropDown == menuData.name)
                                                }>
                                                {dropDown == menuData.name && (
                                                    <motion.div
                                                        key={dropDown}
                                                        initial={{ height: 0 }}
                                                        animate={{
                                                            height: "auto",
                                                        }}
                                                        exit={{ height: 0 }}
                                                        transition={{
                                                            duration: 0.4,
                                                        }}
                                                        onAnimationComplete={() => {
                                                            setShouldAnimate(
                                                                false
                                                            );
                                                        }}
                                                        className={`${
                                                            navbarClasses[
                                                                "seo-item-nav"
                                                            ]
                                                        } ${
                                                            shouldAnimate
                                                                ? "animate-class"
                                                                : ""
                                                        }`}>
                                                        {allData.map(
                                                            (links, id) => {
                                                                if (
                                                                    hasPermission(
                                                                        links.name
                                                                    )
                                                                ) {
                                                                    return (
                                                                        <NavLink
                                                                            key={
                                                                                id
                                                                            }
                                                                            id={
                                                                                1
                                                                            }
                                                                            to={
                                                                                links.menuLink
                                                                            }
                                                                            className={`${
                                                                                Active ===
                                                                                links.active
                                                                                    ? navbarClasses[
                                                                                          "navActive"
                                                                                      ]
                                                                                    : navbarClasses[
                                                                                          "navInactive"
                                                                                      ]
                                                                            } ${
                                                                                !show
                                                                                    ? navbarClasses[
                                                                                          "sub-link-hide"
                                                                                      ]
                                                                                    : ""
                                                                            }`}>
                                                                            {show && (
                                                                                <span
                                                                                    title={
                                                                                        links.name
                                                                                    }>
                                                                                    {
                                                                                        links.name
                                                                                    }
                                                                                </span>
                                                                            )}
                                                                        </NavLink>
                                                                    );
                                                                }
                                                                return null;
                                                            }
                                                        )}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    );
                                }
                                return null;
                            }
                        })}
                    </div>
                </div>
                <div className={navbarClasses["footer"]}>
                    <p>
                        Copyright © 2024 Zefsci.All rights reserved. <br />
                        <span>
                            A Wholly owned subsidary of Shimadzu Scientific
                            Instruments, Inc.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Sidenav;
