import ApiService from 'Services/config';


export const LoginService = (body) => {
 return ApiService.post('admin/login', body)
}
export const verifyOtp = (emailId, OTP) => {

 return ApiService.post(`admin/login/mfa/verify-otp`, { emailId, OTP })
}
export const getAdminUsers = () => {
 return ApiService.get('admin/users/all')
}
export const UpdateAdminUsers = (adminUserId, body) => {
 return ApiService.patch(`admin/${adminUserId}/user`, body)
}
export const addAdminUser = (data) => {
 return ApiService.post('admin/create/user', data)
}
export const DeleteAdminUser = (adminUserId) => {
 return ApiService.delete(`admin/${adminUserId}/user`)
}

// ----------All Customers--------------

export const AllUsers = () => {
 return ApiService.get(`admin/customers/all`)
}
export const getCustomerRoles = () => {
 return ApiService.get(`admin/customer/roles`)
}
export const AssignRole = (CustomerId, body) => {
 return ApiService.patch(`admin/customer/${CustomerId}/assign-role`, body)
}
export const GetPermissions = (RoleId) => {
 return ApiService.get(`admin/customer/roles/permissions?roleId=${RoleId}`)
}
export const updatePermissions = (body) => {
 return ApiService.patch(`/admin/customer/roles/update-permissions`, body)
}
export const getApproveRequests = (status) => {
 return ApiService.get(`admin/approval-requests/status?approvalStatus=${status}`)
}
export const updateRejectStatus = (body) => {
 return ApiService.patch(`/admin/approval-request/update-status`, body)
}
export const GetLogs = (offset) => {
 return ApiService.get(`/admin/logs?Offset=${offset}`)
}
export const GetLogsSearch = (offset, data) => {
 return ApiService.post(`/admin/logs?Offset=${offset}`, data)
}
export const getNotificationCount = () => {
 return ApiService.get(`/admin/notifications/unread`)
}

export const getNotification = (offset, body) => {
 return ApiService.post(`/admin/notifications?Offset=${offset}`, body)
}
export const updateUnreadNotification = () => {
 return ApiService.put(`/admin/notifications/read`)
}
export const updatePlaceID = (userId, body) => {
 return ApiService.patch(`/admin/user/${userId}/update-placeId`, body)
}
export const unlockUser = (body) => {
 return ApiService.patch(`/admin/customer/unlock`, body)
}
export const lockUserApi = (body) => {
 return ApiService.patch(`/admin/customer/lock`, body)
}
export const deleteUser = (id) => {
 return ApiService.delete(`/admin/user/${id}`)
}
