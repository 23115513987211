import { FaUsers } from "react-icons/fa";
import uuid from "react-uuid";
import { PiUsersLight } from "react-icons/pi";
import { FaCheck } from "react-icons/fa";
import { CgNotes } from "react-icons/cg";


import { FaUnlockAlt } from "react-icons/fa";

export const menuItems = [
 {
  id: uuid(),
  multiple: false,
  name: "Admin Users",
  menuLink: "/AdminUsers",
  active: "AdminUsers",
  icon: FaUsers,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Customers",
  menuLink: "/customers",
  active: "Customers",
  icon: PiUsersLight,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Permissions",
  menuLink: "/permissions",
  active: "Permissions",
  icon: FaUnlockAlt,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Approval Requests",
  menuLink: "/approve-request",
  active: "approverequest",
  icon: FaCheck,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Logs",
  menuLink: "/logs",
  active: "logs",
  icon: CgNotes,
 },
]