import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { updateRejectStatus } from "Services/ServiceRequest";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";

Modal.setAppElement("#root");

function ModalCustom({
    getRequest,
    setsubload,
    setModal,
    selected,
    setSelected,
    Status,
    showModal,
}) {
    const [loading, setLoading] = useState(false);
    const ApproveRejectHandler = async (status) => {
        setLoading(true);
        let body = {
            request_approvalId: selected.request_approvalId,
            approvalStatus: status,
        };
        try {
            const response = await updateRejectStatus(body);

            if (response?.data?.Status === 1) {
                setModal(false);
                setSelected(false);
                setLoading(false);
                setsubload(true);
                getRequest(Status);
                Swal.fire({
                    title: "Success",
                    text:
                        body.approvalStatus == 3
                            ? "Update Request Rejected Successfully"
                            : "Update Request Approved Successfully",
                    icon: "success",
                });
            } else {
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const sortObjectKeys = (obj) => {
        const sortedKeys = Object.keys(obj).sort();
        const sortedObj = {};
        sortedKeys.forEach((key) => {
            sortedObj[key] = obj[key];
        });
        return sortedObj;
    };
    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => {
                setModal(false);
                setSelected(false);
            }}
            getRequest={getRequest}
            className={"modal approve-modal"}
            overlayClassName={"overlay"}>
            <div className="Approve_Modal_Container">
                <button
                    className="btn_close"
                    disabled={loading}
                    onClick={() => {
                        setModal(false);
                        setSelected(false);
                    }}>
                    <MdClose />
                </button>
                <h3>{selected?.page}</h3>
                <p>
                    {selected?.section} {`>`}
                    {selected?.permissionName}
                </p>
                <div className="approve_data_wrapper">
                    {selected?.previousData &&
                        Array.isArray(selected?.previousData) && (
                            <div className="Approve_prevData">
                                <label htmlFor="">Previous Data</label>
                                <div className="approve_data_container">
                                    {selected?.previousData
                                        ?.map(sortObjectKeys)
                                        .map((item) => (
                                            <div>
                                                {Object.entries(item).map(
                                                    ([key, value]) => (
                                                        <div
                                                            key={key}
                                                            className="col">
                                                            <strong>
                                                                {key}
                                                            </strong>{" "}
                                                            <p>{value}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    {selected?.modifiedData &&
                        Array.isArray(selected?.modifiedData) && (
                            <div className="Approve_prevData">
                                <label htmlFor="">Modified Data</label>
                                <div className="approve_data_container">
                                    {selected?.modifiedData
                                        ?.map(sortObjectKeys)
                                        .map((item) => (
                                            <div>
                                                {Object.entries(item).map(
                                                    ([key, value]) => (
                                                        <div
                                                            key={key}
                                                            className="col">
                                                            <strong>
                                                                {key}:
                                                            </strong>{" "}
                                                            <p>{value}</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                </div>
                {loading ? (
                    <div className="Request_approve_reject">
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    Status == 2 && (
                        <div className="Request_approve_reject">
                            <button onClick={() => ApproveRejectHandler(1)}>
                                Approve
                            </button>
                            <button onClick={() => ApproveRejectHandler(3)}>
                                Reject
                            </button>
                        </div>
                    )
                )}
            </div>
        </Modal>
    );
}

export default ModalCustom;
