import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { updatePlaceID } from "Services/ServiceRequest";
import userclasses from "./User.module.css";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";

Modal.setAppElement("#root");

function ModalCustom({ setModal, showModal, selectedUser, getRequest }) {
    const [placeId, setplaceId] = useState(selectedUser.placeId);
    const [errormsg, seterrormsg] = useState("");
    const [loading, setLoading] = useState(false);
    const ApproveRejectHandler = async (status) => {
        let body = {
            placeId: placeId,
        };
        if (placeId > 99999) {
            seterrormsg("Max allowed value is 99999");
            return;
        }
        setLoading(true);
        try {
            const response = await updatePlaceID(selectedUser.userId, body);

            if (response?.data?.Status === 1) {
                setModal(false);
                setLoading(false);
                getRequest();
                Swal.fire({
                    title: "Success",
                    text: "Place ID updated Successfully",
                    icon: "success",
                });
            } else {
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => {
                setModal(false);
            }}
            className={"modal"}
            overlayClassName={"overlay"}>
            <div className="Approve_Modal_Container">
                <h3>Update Place ID</h3>
                <button
                    className="btn_close"
                    onClick={() => {
                        setModal(false);
                    }}>
                    <MdClose />
                </button>
                <div className={userclasses["form-control"]}>
                    <label htmlFor="">Place ID</label>
                    <input
                        type="number"
                        value={placeId}
                        onChange={(e) => setplaceId(e.target.value)}
                    />
                    {errormsg && (
                        <span style={{ color: "red" }}>{errormsg}</span>
                    )}
                </div>
                {loading ? (
                    <div className="Request_approve_reject">
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <div className="Request_approve_reject">
                        <button onClick={() => ApproveRejectHandler(1)}>
                            Submit
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ModalCustom;
