
import React from 'react'
import Gaurd from 'Util/Guard';
import { PageRoutes } from './Config'
import { Route, Routes } from "react-router";
import PageNotFound from "Pages/PageNotFound/pageNotFound"
import AccessDenied from "Pages/AccessDenied/denied"
import Login from 'Pages/Login/Login'
const Router = () => {

  const PageData = PageRoutes.map(({ path, element, AccessKey, Type }) => {
    return <Route path={path} key={AccessKey} element={<Gaurd Type={Type} element={element} permissions={AccessKey} key={AccessKey} />} />

  })

  PageData.push(

    <Route key="forbidden" path="/forbidden" element={<AccessDenied />} />,
    <Route key="notfound" path="*" element={<PageNotFound />} />
  );

  return (
    <Routes>
      <Route path='/' element={<Login />} />
      {PageData}</Routes>
  )
}

export default Router