import React, { useState } from "react";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { updatePlaceID } from "Services/ServiceRequest";
import userclasses from "../Customers/User.module.css";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import moment from "moment";
Modal.setAppElement("#root");

function ModalCustom({
    setModal,
    showModal,
    setDate,
    date,
    setname,
    name,
    placeId,
    setplaceId,
    setBody,
    setOffset,
    setHasMore,
    setAllNotifications,
}) {
    const ApproveRejectHandler = async () => {
        let formBody = {
            userName: name,
            placeId: placeId,
            notificationDate: date,
        };
        console.log(formBody);
        if (!formBody.userName) delete formBody.userName;
        if (!formBody.placeId) delete formBody.placeId;
        if (!formBody.notificationDate) delete formBody.notificationDate;
        console.log(formBody);
        setBody(formBody);
        setModal(false);
        setOffset(0);
        setHasMore(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setAllNotifications([]);
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={() => {
                setModal(false);
            }}
            className={"modal"}
            overlayClassName={"overlay"}>
            <div className="Approve_Modal_Container">
                <h3>Filter</h3>
                <button
                    className="btn_close"
                    onClick={() => {
                        setModal(false);
                    }}>
                    <MdClose />
                </button>
                <div className={userclasses["form-control"]}>
                    <label htmlFor="">Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                    />
                </div>
                <div className={userclasses["form-control"]}>
                    <label htmlFor="">Place ID</label>
                    <input
                        type="number"
                        value={placeId}
                        onChange={(e) => setplaceId(e.target.value)}
                    />
                </div>
                <div className={userclasses["form-control"]}>
                    <label htmlFor="">Date</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) =>
                            setDate(moment(e.target.value).format("YYYY-MM-DD"))
                        }
                    />
                </div>
                <div className="Request_approve_reject">
                    <button onClick={() => ApproveRejectHandler(1)}>
                        Submit
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default ModalCustom;
