import create from "zustand";


const initialState = {
  showNavbar: true,
  dropdown: '',
  ActiveTab: '',
  NoOfRecords: 20,
  shouldAnimate: false,
  NotificationCount: 0,


}
const useStore = create((set) => ({
  ...initialState,

  setshowNavbar: (auth) =>
    set((state) => ({ ...state, showNavbar: !state.showNavbar })),
  setShouldAnimate: (data) => set((state) => ({ ...state, shouldAnimate: data })),
  setDropdown: (data) => set((state) => ({ ...state, dropdown: data })),
  setShouldAnimate: (data) => set((state) => ({ ...state, shouldAnimate: data })),
  setActiveTab: (Active) =>
    set((state) => ({ ...state, ActiveTab: Active })),
  setNotificationCount: (count) =>
    set((state) => ({ ...state, NotificationCount: count })),

}));
export default useStore;
