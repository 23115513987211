import ReactLoading from "react-loading";
import React from "react";
import LoaderClasses from "./Loader.module.css";
function Loader({ loading, children }) {
    return (
        <>
            {loading ? (
                <div className={LoaderClasses["loader"]}>
                    <ReactLoading
                        color="blue"
                        type="spokes"
                        height={30}
                        width={30}
                    />
                </div>
            ) : (
                children
            )}
        </>
    );
}

export default Loader;
