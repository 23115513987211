import React from "react";
import LayoutClasses from "./Layout.module.css";
import SideNav from "./SideNav/Navbar";
import useStore from "Store";

import TopNav from "Components/Layout/topNav/index";
import { useEffect } from "react";
function Layout({ children, Active, showBackButton, title }) {
    const showNav = useStore((state) => state.showNavbar);
    const setShowNav = useStore((state) => state.setshowNavbar);
    const setActiveTab = useStore((State) => State.setActiveTab);
    useEffect(() => {
        setActiveTab(Active);
    }, []);
    return (
        <div className={LayoutClasses["Wrapper"]}>
            <SideNav Active={Active} setShow={setShowNav} show={showNav} />

            <div
                className={`${LayoutClasses["main-content"]} ${
                    !showNav ? LayoutClasses["nav-hide"] : ""
                }`}>
                <TopNav showBackButton={showBackButton} title={title} />
                <div
                    style={{ zIndex: "2" }}
                    className={LayoutClasses["children"]}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;
