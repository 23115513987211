import React, { useState, useEffect } from "react";
import Layout from "Components/Layout";
import { FaPlus } from "react-icons/fa6";
import Loader from "Components/Loader/Loader";
import { MdDelete, MdEdit } from "react-icons/md";
import AddUsers from "./AddUser";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { getAdminUsers, DeleteAdminUser } from "Services/ServiceRequest";
import Subloader from "Components/Loader/subload/loader";
function User() {
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [subLoad, setSubLoad] = useState(false);
    const [Modal, setModal] = useState(false);
    const [Users, setUsers] = useState([]);

    const GetRequests = async () => {
        // setLoading(true);
        try {
            const response = await getAdminUsers();
            setLoading(false);
            if (response?.data?.Status === 1) {
                setUsers(response?.data?.AdminUsers);
            } else {
            }
        } catch (err) {
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        GetRequests();
    }, []);

    const DeleteUser = async (id) => {
        setSubLoad(true);
        try {
            const response = await DeleteAdminUser(id);
            setSubLoad(false);
            if (response?.data?.Status === 1) {
                Swal.fire({
                    title: "Success",
                    text: response?.data?.Message
                        ? response?.data?.Message
                        : "Admin Delete Success",
                    icon: "success",
                });
                GetRequests();
            } else {
            }
        } catch (error) {
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "warning",
                text: error?.response?.data?.error
                    ? error?.response?.data?.error?.name
                    : "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    const DeleteHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        swal(`Are You sure you want to Delete User?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: "Logout",
                    className: "Delete",
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    DeleteUser(id);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const EditHandler = (item) => {
        localStorage.setItem("prefillData", JSON.stringify(item));
        setModal(true);
        setEdit(true);
    };
    return (
        <Layout
            Active={"AdminUsers"}
            showBackButton={false}
            title={"Admin Users"}>
            <AddUsers
                showModal={Modal}
                edit={edit}
                setEdit={setEdit}
                setModal={setModal}
                GetRequests={GetRequests}
            />
            <button className="filter" onClick={() => setModal(true)}>
                <FaPlus color="orange" />
                Add Admin
            </button>
            {subLoad && <Subloader />}

            <div className="Main_container">
                <h3>Admin Users</h3>
                <Loader loading={loading}>
                    <div className="Table_layout">
                        <table>
                            <thead className="sticky-header">
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email ID</th>
                                    <th>Role</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Users.length > 0 ? (
                                    Users.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.emailId}</td>
                                            <td>
                                                {item?.role == 1
                                                    ? "Administrator"
                                                    : "Account Manager"}
                                            </td>
                                            <td>
                                                <div className="Action_btns">
                                                    <button
                                                        onClick={() => {
                                                            EditHandler(item);
                                                        }}>
                                                        <MdEdit />
                                                    </button>
                                                    <button
                                                        onClick={DeleteHandler}
                                                        id={item.adminUserId}>
                                                        <MdDelete color="red" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {" "}
                                        <td colSpan={4}>No Data Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Loader>
            </div>
        </Layout>
    );
}

export default User;
