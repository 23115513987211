import React, { useState, useEffect } from "react";
import { FaBell, FaUser } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { FaRegBell } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";
import profileClasses from "./topNav.module.css";
import { MdOutlineLogout } from "react-icons/md";
import { getNotificationCount } from "Services/ServiceRequest";
import swal from "sweetalert";
import useStore from "Store";
function Profile_header({ title, showBackButton }) {
    // const navigate = useNavigate();
    const Navigate = useNavigate();
    const { NotificationCount, setNotificationCount } = useStore(
        (state) => state
    );
    const profile = () => {
        // Navigate("/profile/Myprofile");
    };
    const BackAction = () => {
        Navigate(-1);
    };
    const LogoutHandler = () => {
        swal("Are You sure you want to Sign out?", {
            icon: "warning",
            className: profileClasses["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Logout",
                    value: "Logout",
                    className: profileClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Navigate("/");
                    localStorage.clear();
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };

    const GetRequests = async () => {
        // setLoading(true);
        try {
            const response = await getNotificationCount();
            // setLoading(false);
            if (response?.data?.Status === 1) {
                setNotificationCount(response?.data?.UnreadNotifications);
            } else {
            }
        } catch (err) {
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        GetRequests();
    }, []);
    return (
        <div className={profileClasses["profile_header_wrapper"]}>
            <div className={profileClasses["profile_header"]}>
                <div className={profileClasses["title-container"]}>
                    {showBackButton && (
                        <SlArrowLeft
                            className={profileClasses["myprofile_title_icon"]}
                            onClick={BackAction}
                        />
                    )}
                    <h4 className={profileClasses["profile_title"]}>{title}</h4>
                </div>
                <div className={profileClasses["profile_topbar"]}>
                    <div
                        className={profileClasses["profile_notification_icon"]}
                        onClick={() => Navigate("/notifications")}>
                        <FaBell />
                        <p>{NotificationCount}</p>
                    </div>

                    <div
                        className={profileClasses["profile_user_info"]}
                        onClick={profile}>
                        <div className={profileClasses["profile_icon_user"]}>
                            <FaUser
                                className={profileClasses["profile_icon"]}
                            />{" "}
                        </div>
                        <div className={profileClasses["profile_name"]}>
                            {" "}
                            {localStorage.getItem("fullName") || "user"}
                        </div>
                        <div
                            className={
                                profileClasses["profile_language_selector"]
                            }
                            onClick={LogoutHandler}>
                            <MdOutlineLogout />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile_header;
