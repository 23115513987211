import React, { useState, useEffect } from "react";
import Layout from "Components/Layout";
import UserClasses from "./User.module.css";
import Loader from "Components/Loader/Loader";
import { IoSearchSharp } from "react-icons/io5";
import { MdEdit, MdDelete } from "react-icons/md";
import FilterModal from "./Filter/Filter";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { CiFilter } from "react-icons/ci";
import {
    AllUsers,
    AssignRole,
    getCustomerRoles,
    unlockUser,
    deleteUser,
    lockUserApi,
} from "Services/ServiceRequest";
import Subloader from "Components/Loader/subload/loader";
import Modal from "./Modal";
function User() {
    const [loading, setLoading] = useState(true);
    const [subLoad, setSubLoad] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const [Filters, setFilters] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [internalFilter, setInteralfilter] = useState([]);

    const [search, setSearch] = useState([]);
    const [Roles, setRoles] = useState([]);
    const [Users, setUsers] = useState([]);
    const [showModal, setModal] = useState(false);
    const fetchAllUsersAndRoles = async () => {
        try {
            const [usersResponse, rolesResponse] = await Promise.all([
                AllUsers(),
                getCustomerRoles(),
            ]);
            setLoading(false);
            if (
                usersResponse?.data?.Status == 1 &&
                rolesResponse.data?.Status == 1
            ) {
                if (localStorage.getItem("UserFilter")) {
                    let ExistingFilter = JSON.parse(
                        localStorage.getItem("UserFilter")
                    );
                    if (ExistingFilter) {
                        let filteredResults = usersResponse?.data?.Users;
                        for (const filter of ExistingFilter) {
                            filteredResults = filterObjectsByBkey(
                                filteredResults,
                                filter
                            );
                            console.log(filteredResults);
                        }
                        // console.log(filteredResults);

                        setSearch([...filteredResults]);
                        setFilters(ExistingFilter);
                    }
                } else {
                    setUsers(usersResponse?.data?.Users);
                    setSearch(usersResponse?.data?.Users);
                }
                setRoles(rolesResponse?.data?.roles);
            }
        } catch (error) {
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const clearhandler = () => {
        setFilters([]);
        setInteralfilter([]);
        localStorage.removeItem("UserFilter");
        setUsers(search);
        // setSelected([]);
        setIsOpen(false);
    };
    useEffect(() => {
        fetchAllUsersAndRoles();
    }, []);
    const RoleUpdate = async (value, id) => {
        setSubLoad(true);
        let body = { roleId: value };
        try {
            const response = await AssignRole(id, body);
            setSubLoad(false);
            if (response?.data?.Status === 1) {
                fetchAllUsersAndRoles();
                Swal.fire({
                    title: "Success",
                    text: response?.data?.Message
                        ? response?.data?.Message
                        : "Role Update Success",
                    icon: "success",
                });
            } else {
            }
        } catch (error) {
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "warning",
                text: error?.response?.data?.error
                    ? error?.response?.data?.error?.name
                    : "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    function filterObjectsByBkey(objects, filter) {
        const { Bkey, keys } = filter;
        console.log(objects, keys, filter);
        return objects.filter((obj) => keys.includes(obj[Bkey]));
    }
    const ChangeHandler = (roleValue, userId, fullName) => {
        console.log(roleValue, userId);
        swal(
            `Are You sure you want to Update Role for ${fullName} as ${
                roleValue == 1 ? "Basic" : "Advanced"
            } User?`,
            {
                icon: "warning",
                dangerMode: true,
                buttons: {
                    cancel: "Cancel",
                    catch: {
                        text: "Update",
                        value: "Logout",
                        className: "Delete",
                    },
                    defeat: false,
                },
            }
        ).then((value) => {
            switch (value) {
                case "Logout":
                    RoleUpdate(roleValue, userId);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const changeHandler = (e) => {
        setSearchString(e.target.value);
        if (e.target?.value?.length > 0) {
            let searchData = search;
            console.log(Filters, search);
            if (Filters.length > 0) {
                for (const filterData of Filters) {
                    searchData = filterObjectsByBkey(search, filterData);
                }
            }
            searchData = searchData.filter((user) =>
                user.emailId
                    .toLowerCase()
                    .includes(String(e.target.value).toLowerCase())
            );

            setUsers(searchData);
        } else {
            if (Filters.length > 0) {
                let searchData;
                for (const filterData of Filters) {
                    searchData = filterObjectsByBkey(search, filterData);
                }
                setUsers(searchData);
            } else {
                setUsers(search);
            }
        }
    };
    const DeleteUser = async (id) => {
        setSubLoad(true);
        try {
            const response = await deleteUser(id);
            setSubLoad(false);
            if (response?.data?.Status === 1) {
                Swal.fire({
                    title: "Success",
                    text: response?.data?.Message
                        ? response?.data?.Message
                        : "Admin Delete Success",
                    icon: "success",
                });
                fetchAllUsersAndRoles();
            } else {
            }
        } catch (error) {
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "warning",
                text: error?.response?.data?.error
                    ? error?.response?.data?.error?.name
                    : "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    const DeleteHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        swal(`Are You sure you want to Delete User?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: "Logout",
                    className: "Delete",
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    DeleteUser(id);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const unlock = async (id, type) => {
        setSubLoad(true);
        // let body = { roleId: value };
        try {
            let response;
            if (type == "lock") {
                response = await lockUserApi({ userId: id });
            } else {
                response = await unlockUser({ userId: id });
            }
            setSubLoad(false);
            if (response?.data?.Status === 1) {
                fetchAllUsersAndRoles();
                Swal.fire({
                    title: "Success",
                    text: response?.data?.Message
                        ? response?.data?.Message
                        : "User Unlock Success",
                    icon: "success",
                });
            } else {
            }
        } catch (error) {
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "warning",
                text: error?.response?.data?.error
                    ? error?.response?.data?.error?.name
                    : "Something went wrong please try again",
                icon: "error",
            });
        }
    };
    const unlockhandler = (item, type) => {
        swal(`Are you sure you want to ${type} ${item?.fullName}?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: type,
                    value: "Logout",
                    className: "Delete",
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    unlock(item?.userId, type);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <Layout Active={"Customers"} showBackButton={false} title={"Customers"}>
            {subLoad && <Subloader />}
            {showModal && (
                <Modal
                    showModal={showModal}
                    setModal={setModal}
                    selectedUser={selectedUser}
                    getRequest={fetchAllUsersAndRoles}
                />
            )}
            {isOpen && (
                <FilterModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    data={search}
                    setEquipmentFilters={setUsers}
                    filters={Filters}
                    internalFilter={internalFilter}
                    searchString={searchString}
                    setInteralfilter={setInteralfilter}
                    setFilters={setFilters}
                />
            )}
            <div className="Filter-wrapper">
                <button
                    className="filter"
                    style={{ margin: 0 }}
                    onClick={() => setIsOpen(true)}>
                    <CiFilter className="filter-icon" />
                    Filter
                </button>
                <div className="form_control_Search">
                    <input
                        type="text"
                        placeholder="Search By Email "
                        onChange={changeHandler}
                    />
                    <IoSearchSharp
                        className={"search_icon"}
                        size={22}
                        color="#061739"
                    />
                </div>
            </div>

            <div className={"Filters_wrapper"}>
                {Filters.map((item) => (
                    <div className={"Filters"}>
                        <h3>{item?.FilterName}</h3>

                        <p>{item?.label?.join(",")}</p>
                    </div>
                ))}
                {Filters.length > 0 && (
                    <button className="clear_filter_btn" onClick={clearhandler}>
                        {/* <CiFilter className="filter-icon" /> */}
                        Clear
                    </button>
                )}
            </div>
            <div className="Main_container">
                <h3>Customers</h3>
                <Loader loading={loading}>
                    <div className="Table_layout">
                        <table>
                            <thead className="sticky-header">
                                <tr>
                                    <th>User ID</th>
                                    <th>Full Name</th>
                                    <th>Email ID</th>
                                    <th>Contact ID</th>
                                    <th>Rating</th>
                                    <th>Review</th>
                                    <th>Place ID</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Users.length > 0 ? (
                                    Users.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.userId}</td>
                                            <td>{item?.fullName}</td>
                                            <td>{item?.emailId}</td>
                                            <td>{item?.contactId}</td>
                                            <td>{item.rating}</td>
                                            <td>{item.review}</td>
                                            <td>
                                                {item?.placeId}{" "}
                                                <button
                                                    className={
                                                        UserClasses[
                                                            "edit-placeid"
                                                        ]
                                                    }
                                                    onClick={() => {
                                                        setModal(true);
                                                        setSelectedUser(item);
                                                    }}>
                                                    <MdEdit />
                                                </button>
                                            </td>
                                            <td>
                                                <div
                                                    className={
                                                        UserClasses["Roles"]
                                                    }>
                                                    <select
                                                        value={item?.roleId}
                                                        onChange={(e) => {
                                                            ChangeHandler(
                                                                e.target.value,
                                                                item?.userId,
                                                                item?.fullName
                                                            );
                                                        }}>
                                                        <option
                                                            value={0}
                                                            disabled>
                                                            Select Role
                                                        </option>
                                                        {Roles?.map(
                                                            (roleData) => (
                                                                <option
                                                                    value={
                                                                        roleData.roleId
                                                                    }>
                                                                    {" "}
                                                                    {
                                                                        roleData?.roleName
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                {item.status == 0 ? (
                                                    <span
                                                        className="Status-codes"
                                                        style={{
                                                            color: "#FF6600",
                                                            border: `1px solid
                                                    ${"#FF6600"}`,
                                                        }}>
                                                        Inactive
                                                    </span>
                                                ) : item.status == 1 ? (
                                                    <span
                                                        className="Status-codes"
                                                        style={{
                                                            color: "#10b981",
                                                            border: `1px solid
                                                        ${"#10b981"}`,
                                                        }}>
                                                        Active
                                                    </span>
                                                ) : (
                                                    <span
                                                        className="Status-codes"
                                                        style={{
                                                            color: "#FF0000",
                                                            border: `1px solid
                                                    ${"#FF0000"}`,
                                                        }}>
                                                        Locked
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <div className="Action_btns_user">
                                                    {item.status == 2 && (
                                                        <button
                                                            className={
                                                                UserClasses[
                                                                    "unlock"
                                                                ]
                                                            }
                                                            onClick={() =>
                                                                unlockhandler(
                                                                    item,
                                                                    "unlock"
                                                                )
                                                            }>
                                                            Unlock
                                                        </button>
                                                    )}
                                                    {item.status == 1 && (
                                                        <button
                                                            className={
                                                                UserClasses[
                                                                    "unlock"
                                                                ]
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "orangered",
                                                            }}
                                                            onClick={() =>
                                                                unlockhandler(
                                                                    item,
                                                                    "lock"
                                                                )
                                                            }>
                                                            Lock
                                                        </button>
                                                    )}

                                                    <button
                                                        className={"delete"}
                                                        onClick={DeleteHandler}
                                                        id={item.userId}>
                                                        <MdDelete color="red" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        {" "}
                                        <td colSpan={9}>No Data Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Loader>
            </div>
        </Layout>
    );
}

export default User;
