import React, { useEffect, useState } from "react";
import Layout from "Components/Layout";
import PermissionClasses from "Pages/Permissions/Permissions.module.css";
import { GetPermissions, updatePermissions } from "Services/ServiceRequest";
import Swal from "sweetalert2";
import Loader from "Components/Loader/Loader";
import Subloader from "Components/Loader/subload/loader";
function Permissions() {
    const [PermissionLevel, setPermissionLevel] = useState(1);
    const [subLoad, setSubLoad] = useState(false);
    const [Permissions, setPermissions] = useState({ RolePermissions: [] });
    const [loading, setLoading] = useState(true);

    const GetRequests = async (Level) => {
        // setLoading(true);
        try {
            const response = await GetPermissions(Level);

            if (response?.data?.Status === 1) {
                setPermissions(response?.data);
            } else {
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    useEffect(() => {
        GetRequests(PermissionLevel);
    }, [PermissionLevel]);

    const ChangeHandler = (e) => {
        setLoading(true);
        setPermissionLevel(e.target.value);
    };
    const permissionHandler = async (e, ID) => {
        setSubLoad(true);
        let body = {
            rpId: ID,
            status: e.target.checked ? 1 : 0,
        };
        try {
            const response = await updatePermissions(body);

            if (response?.data?.Status === 1) {
                Swal.fire({
                    title: "Success",
                    text: response?.data?.Message,
                    icon: "success",
                });
            }
            setSubLoad(false);
        } catch (err) {
            setSubLoad(false);
            console.log("err");
            Swal.fire({
                title: "Error",
                text: "Something went wrong please try again",
                icon: "error",
            });
        }
    };

    return (
        <Layout
            Active={"Permissions"}
            title={"Permissions"}
            showBackButton={false}>
            {subLoad && <Subloader />}
            <div className={PermissionClasses["Container"]}>
                <div className={PermissionClasses["Filter"]}>
                    <select onChange={ChangeHandler}>
                        <option value={1}>Basic</option>
                        <option value={2}>Advanced</option>
                    </select>
                </div>
                <div className={PermissionClasses["Sub_wrapper"]}>
                    <h3>
                        Permissions (
                        {PermissionLevel == 1 ? "Basic" : "Advanced"} User)
                    </h3>
                    <Loader loading={loading}>
                        <div
                            className={PermissionClasses["Permission_wrapper"]}>
                            {Permissions.RolePermissions?.map(
                                (permissionLoop) => (
                                    <div
                                        className={
                                            PermissionClasses["Permission"]
                                        }>
                                        <h4>{permissionLoop.PageName}</h4>
                                        <div>
                                            {permissionLoop?.Sections?.map(
                                                (sectionsLoop) => (
                                                    <div
                                                        className={
                                                            PermissionClasses[
                                                                "Section_wrapper"
                                                            ]
                                                        }>
                                                        <h3>
                                                            {
                                                                sectionsLoop?.SectionName
                                                            }
                                                        </h3>
                                                        <div
                                                            className={
                                                                PermissionClasses[
                                                                    "SubSection"
                                                                ]
                                                            }>
                                                            {sectionsLoop.SectionPermissions.map(
                                                                (
                                                                    subsection
                                                                ) => (
                                                                    <div
                                                                        className={
                                                                            PermissionClasses[
                                                                                "user_permission"
                                                                            ]
                                                                        }>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={
                                                                                subsection?.rpId
                                                                            }
                                                                            defaultChecked={
                                                                                subsection?.status ==
                                                                                1
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                permissionHandler(
                                                                                    e,
                                                                                    subsection?.rpId
                                                                                )
                                                                            }
                                                                        />
                                                                        <label
                                                                            htmlFor={
                                                                                subsection?.rpId
                                                                            }>
                                                                            {
                                                                                subsection.permissionName
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </Loader>
                </div>
            </div>
        </Layout>
    );
}

export default Permissions;
