
import React from 'react'
import { Navigate, } from "react-router-dom";


const Gaurd = ({ element }) => {
  // function to check if the user has required permissions
  function hasPermissions() {
    let storageData = localStorage.getItem("Token");

    if (storageData) {
      return true
    }
    return false;
  }
  return hasPermissions() ? element :
    <Navigate to="/forbidden" replace />
}

export default Gaurd